export function goTo(selector) {
  var element = document.getElementById(selector)
  if (element) {
    window.scrollTo(
      0,
      window.innerHeight -
        (element.getBoundingClientRect().height + element.offsetTop),
    )
  }
}

export function getFriendlySubscriptionName(name) {
  let friendlyName = name
  if (name.includes('pay-as-you-go')) {
    friendlyName = 'Pay-as-you-go'
  }
  if (name === 'free') {
    friendlyName = 'Free'
  }
  return friendlyName
}
